<script>
import {BButton, BFormInput} from "bootstrap-vue";
import store from "@/store";
import {
  onMounted,
  ref,
} from "@vue/composition-api";
import whitelabelTemplatesAppAuthModule
  from "@/views/components/whitelabel-templates/App/config/PageEdit/AuthManagement/store/authStoreModule";

export default {
  name: "RegisterView",
  components: {BButton, BFormInput},
  setup(props, {emit}) {
    const MODULE_APP_STORE_NAME_2 = "app-whitelabel-templates-auth";
    // Register module
    if (!store.hasModule(MODULE_APP_STORE_NAME_2))
      store.registerModule(
          MODULE_APP_STORE_NAME_2,
          whitelabelTemplatesAppAuthModule
      );

    const isAuthLoginModalModify = ref(false);
    const isAuthRegisterModalModify = ref(false);

    const setAuthRegisterModal = (flag) => {
      isAuthRegisterModalModify.value = flag;
      isAuthLoginModalModify.value = false;
      store.commit(`${MODULE_APP_STORE_NAME_2}/SHOW_REGISTER_MODAL`);
    };

    const prepareUpdateOrCreate = (item = null, action = "edit") => {
      emit("updateOrCreate", {item, action});
    };

    onMounted(() => {
      setAuthRegisterModal(true);
    });

    return {
      prepareUpdateOrCreate,
    };
  },
};
</script>

<template>
  <b-button size="md" @click="prepareUpdateOrCreate(null, 'edit')" class="w-100">
    Register Edit
  </b-button>
</template>

<style scoped lang="scss">
.list-group {
  max-height: 300px;
  overflow-y: scroll;
  width: 300px;
}

.color-box {
  width: 50px;
}
</style>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
} from "bootstrap-vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {computed, onMounted, onUnmounted, ref, watch} from "@vue/composition-api";
import store from "@/store";
import menuStoreModule from "./store/menuStoreModule";
import axios from "@/libs/axios";
import vSelect from "vue-select";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import i18n from "@/libs/i18n";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";
import ItemList from "@/views/components/whitelabel-templates/common/itemList.vue";
import ItemForm from "@/views/components/whitelabel-templates/common/itemForm.vue";
import {UUIDGenerator} from "@/views/components/whitelabel-templates/common/utils/uuidGenerator";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {showErrorToast, showSuccessToast} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "index",
  components: {
    ItemForm,
    ItemList,
    BFormInvalidFeedback,
    ImageFieldForm,
    vSelect,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BFormGroup,
  },

  props: {
    options: {
      default: {
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "menu",
        visible: false,
      },
    },
  },

  setup(props, {emit}) {
    const MENU_APP_STORE_MODULE_NAME = "app-menu-store";
    const menu = ref({
      background_color: "",
      name: "",
      font_size: "",
      hover_color: "",
      color: "",
      links: [],
    });


    const images = ref([]);
    const item = ref({
      href: "",
      title: "",
      position: "",
      buttonType: "",
      children: []
    }); // Represents the current link being edited/created

    const uuidGen = UUIDGenerator();
    const rBuilder = RequestBuilder();

    const {
      settings,
      resetForm,
      toast,
      loading,
      showForm,
      getValidationState,
      editTemplate,
      refFormObserver,
      showList,
      enableEditMode,
      enableAddMode,
      formValid,
      setIndex
    } = UseIndex();


    if (!store.hasModule(MENU_APP_STORE_MODULE_NAME))
      store.registerModule(MENU_APP_STORE_MODULE_NAME, menuStoreModule);

    onMounted(() => {
      resetMenu();
      const {itemsSelected} = props.options;
      if (itemsSelected) menu.value = itemsSelected;
      updatePreview();
    });

    // Fetch images from the server for the current client's menu
    const storeImage = async () => {
      axios
          .get(
              `${APP_URL}/files/${store.state.whitelabelCurrencyNabvar.whitelabel.client}?path=menu`
          )
          .then((response) => {
            images.value = response.data;
          })
          .catch((error) => {
            console.error(error);
            if (error.response.status === 404) {
              images.value = [];
            }
          });
    };

    const editItemHandler = (val) => {
      const exist =
          menu.value.links.find((item) => item._id === val._id) !== undefined;
      if (exist) {
        item.value = val;
        enableEditMode();
        showForm();
      } else {
        resetItem();
        showList();
      }
      updatePreview();
    };

    const resetItem = () => {
      item.value = {
        href: "",
        title: "",
        position: "",
        buttonType: "",
        children: []
      };
    };

    const resetAll = (val) => {
      if (val) {
        loadItemFromCache(val)
      }
      resetItem();
      showList();
      store.commit("app-whitelabel-templates-menu/SET_CLICKED_LINK", null);
    };

    const loadItemFromCache = (val) => {
      menu.value.links[val.index] = val.item
    }

    const updateItem = () => {
      menu.value.links = menu.value.links.map((i) => {
        if (i.id === item.value.id) {
          return item.value;
        }
        return i;
      });
      updatePreview();
      showList();
      resetAll();
      sortMenuItems();
    };

    const saveItem = () => {
      item.value = {
        _id: uuidGen.generate(),
        ...item.value,
      };
      menu.value.links.push(item.value);
      updatePreview();
      showList();
      resetAll();
      sortMenuItems();
    };

    const sortMenuItems = () => {
      const links = menu.value.links;
      links.sort((a, b) => Number(a.position) - Number(b.position));
      menu.value.links = links;
    };

    const onSubmit = () => {
      if (isEditMode.value) {
        updateMenu();
      } else {
        createMenu();
      }
    };


    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
            "app-whitelabel-templates/updateTemplate",
            {
              template: editTemplate.value,
              toast,
              path: 'templateData.menu'
            }
        );

      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createMenu = async () => {
      loading.value = true;
      menu.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      menu.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "app-menu-store/addMenu",
            rBuilder.clean(menu.value)
        );

        showSuccessToast(toast, "Menu", i18n.t("menu_created"));
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_menu"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateMenu = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
            "app-menu-store/updateMenu",
            {
              id: menu.value._id,
              menuData: rBuilder.clean(menu.value),
            }
        );

        showSuccessToast(toast, "Menu", i18n.t("menu_updated"));
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_menu"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.menu");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      resetAll();
      emit("reset");
    };

    const isEditMode = computed(() => props.options.action === "edit");

    const resetMenu = () => {
      menu.value = {
        background_color: "",
        name: "",
        font_size: "",
        hover_color: "",
        color: "",
        links: [],
      };
    };

    const fetchImages = async () => {
      axios
          .get(
              `${APP_URL}/files/${store.state.whitelabelCurrencyNabvar.whitelabel.client}?path=menu`
          )
          .then((response) => {
            images.value = response.data;
          })
          .catch((error) => {
            console.error(error);
            if (error.response.status === 404) {
              images.value = [];
            }
          });
    };

    const setOrReloadImages = (value) => {
      fetchImages();
      menu.value.background_image = value;
    };

    const onEditItem = (val) => {
      showForm();
      setIndex(val.index)
      enableEditMode();
      item.value = val.item;
    };

    const onAddItem = () => {
      showForm();
      enableAddMode();
      resetItem();
      updatePreview();
    };

    const onDeleteItem = (index) => {
      menu.value.links.splice(index, 1)
      updatePreview();
    };

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          menu: value || menu.value,
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const updateDynamicValue = (value, path) => {
      const properties = path.split('.');
      const last = properties.pop();
      const target = properties.reduce((acc, prop) => acc[prop], menu.value);
      if (target) {
        target[last] = value;
      }
    }

    const onClearImage = (path) => {
      updateDynamicValue('', path)
    }

    const onUploadedImage = (data, path) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        updateDynamicValue(image, path)
        updatePreview();
      }
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    return {
      editTemplate,
      setOrReloadImages,
      editItemHandler,
      images,
      settings,
      storeImage,
      onSubmit,
      resetMenu,
      fetchImages,
      refFormObserver,
      getValidationState,
      resetForm,
      isEditMode,
      resetData,
      loading,
      item,
      menu,
      resetItem,
      resetAll,
      onEditItem,
      onDeleteItem,
      onAddItem,
      updatePreview,
      updateItem,
      formValid,
      saveItem,
      onClearImage,
      errorInLoadImage,
      onUploadedImage,
    };
  },

  mounted() {
    this.storeImage();
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-row>
            <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
                class="w-100"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="name">
                  <template #label>{{ $t("labels.name") }}</template>
                  <b-form-input
                      id="name"
                      type="text"
                      v-model="menu.name"
                      :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
                #default="validationContext"
                name="fontSize"
                class="w-100"
            >
              <b-col cols="12">
                <b-form-group label="With" label-for="fontSize">
                  <template #label>{{ $t("font_size") }}</template>
                  <b-form-input
                      id="fontSize"
                      type="number"
                      v-model="menu.font_size"
                      :state="getValidationState(validationContext)"
                  />

                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
                #default="validationContext"
                name="BackgroundColor"
                rules="required"
                class="w-100"
            >
              <b-col cols="12">
                <b-form-group
                    label="Background color"
                    label-for="backgroundcolor"
                >
                  <template #label>{{ $t("labels.backgroundcolor") }}</template>
                  <b-form-input
                      id="backgroundcolor"
                      type="color"
                      v-model="menu.background_color"
                      :state="getValidationState(validationContext)"
                  />

                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
                #default="validationContext"
                name="textColor"
                rules="required"
                class="w-100"
            >
              <b-col cols="12">
                <b-form-group label="text color" label-for="textColor">
                  <template #label>{{ $t("text_color") }}</template>
                  <b-form-input
                      id="textColor"
                      type="color"
                      v-model="menu.color"
                      :state="getValidationState(validationContext)"
                  />

                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
                #default="validationContext"
                name="hoverColor"
                rules="required"
                class="w-100"
            >
              <b-col cols="12">
                <b-form-group label="text color" label-for="hoverColor">
                  <template #label>{{ $t("labels.hovercolor") }}</template>
                  <b-form-input
                      id="hoverColor"
                      type="color"
                      v-model="menu.hover_color"
                      :state="getValidationState(validationContext)"
                  />

                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <b-col cols="12">
              <image-field-form
                  :single-button="true"
                  :preview="true"
                  id="backgroundImage"
                  :text="$t('labels.backgroundimage')"
                  @uploadedImage="onUploadedImage($event, 'background_image')"
                  @error="errorInLoadImage"
                  path="menu"
                  :value="menu.background_image"
              >
              </image-field-form>

              <div v-if="menu.background_image" class="row">
                <div class="col">

                  <img
                      style="width: 100%"
                      :src="menu.background_image"
                      alt="Uploaded Image"
                      class="mt-2 rounded"
                  />

                  <b-button class="close-btn" @click="onClearImage('background_image')" variant="outline-primary"
                            size="sm">
                    X
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Settings">
          <item-list
              :items="menu.links"
              v-if="settings.status === 'list'"
              @add="onAddItem()"
              @edit="onEditItem($event)"
              @delete="onDeleteItem($event)"
          >
          </item-list>
          <item-form
              class="mt-1"
              @reset="resetAll($event)"
              @save="saveItem()"
              @update="updateItem()"
              @iconSelected="updatePreview()"
              :settings="settings"
              :item="item"
              :template="editTemplate"
              v-if="settings.status === 'form'"
          >
          </item-form>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()">{{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss">
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
}
</style>

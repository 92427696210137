<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import { computed, onMounted, ref } from "@vue/composition-api";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import {useToast} from "vue-toastification/composition";
import store from "@/store";
import headerStoreModule from "./store/headerStoreModule";
import i18n from "@/libs/i18n";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";

export default {
  name: "FEditHeader",
  components: {
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      type: Object,
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "header",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const HEADER_APP_STORE_MODULE_NAME = "app-header-store";
    const header = ref(null);
    const rBuild = RequestBuilder();
    const debounceTimer = ref(null);

    // Register modules
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(HEADER_APP_STORE_MODULE_NAME))
      store.registerModule(HEADER_APP_STORE_MODULE_NAME, headerStoreModule);

    const selectOptions = ref([
      {text: i18n.t("left"), value: 1},
      {text: i18n.t("center"), value: 2},
      {text: i18n.t("right"), value: 3},
    ]);

    onMounted(() => {
      resetHeader();
      const {itemsSelected} = props.options;
      if (itemsSelected) header.value = itemsSelected;
      updatePreview();
    });

    const isEditMode = computed(() => props.options.action === "edit");

    const onSubmit = () => {
      if (isEditMode.value) {
        updateHeader();
      } else {
        createHeader();
      }
    };

    const {
      resetForm,
      toast,
      loading,
      getValidationState,
      editTemplate,
      refFormObserver,
    } = UseIndex();

    const updateTemplate = async () => {
      try {
        await store.dispatch("app-whitelabel-templates/updateTemplate", {
          template: editTemplate.value,
          toast,
          path: "templateData.header"
        });
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createHeader = async () => {
      loading.value = true;
      header.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      header.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "app-header-store/addHeader",
            rBuild.clean(header.value)
        );

        showSuccessToast(toast, "Header", i18n.t("header_created"));
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_update_header"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateHeader = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch("app-header-store/updateHeader", {
          id: header.value._id,
          headerData: rBuild.clean(header.value),
        });

        showSuccessToast(toast, "Header", i18n.t("header_updated"));
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_update_header"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetHeader = () => {
      header.value = {
        background_image: "",
        background_color: "",
        name: "",
        title: {
          text: "",
          color: "",
          font_size: "",
          hide: false,
          position: 0,
        },
        auth: {
          color: "",
          background_color: "",
          text_color: "",
          position: 0,
          hide: false,
        },
        logo: {
          href: "",
          width: "",
          height: "",
          hide: false,
          position: 0,
        },
      };
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.header");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          header: value || header.value
        }
      };

      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const updateDynamicValue = (value, path) => {
      const properties = path.split('.');
      const last = properties.pop();
      const target = properties.reduce((acc, prop) => acc[prop], header.value);
      if (target) {
        target[last] = value;
      }
    }

    const onUploadedImage = (data, path) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        updateDynamicValue(image, path)
        updatePreview();
      }
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onColorChange = ({key, value}) => {
      const keys = key.split(".");
      let current = header.value;
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;

      if (debounceTimer.value) {
        clearTimeout(debounceTimer.value);
      }

      debounceTimer.value = setTimeout(() => {
        updatePreview();
      }, 1000);
    };

    const onClearImage = (path) => {
      updateDynamicValue('', path)
    }

    return {
      isEditMode,
      onSubmit,
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      selectOptions,
      header,
      loading,
      errorInLoadImage,
      onUploadedImage,
      onColorChange,
      updatePreview,
      onClearImage
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form
          v-if="header"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-list-group-item style="background: none" class="px-0">
            <b-row>
              <validation-provider
                  class="w-100"
                  #default="validationContext"
                  name="name"
                  rules="required"
              >
                <b-col cols="12">
                  <b-form-group label="name" label-for="h-name">
                    <template #label>{{ $t("labels.name") }}</template>
                    <b-form-input
                        id="h-name"
                        type="text"
                        v-model="header.name"
                        @input="updatePreview()"
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </b-col>
              </validation-provider>
            </b-row>

            <b-row>
              <b-col cols="12">
                <validation-provider
                    #default="validationContext"
                    name="BackgroundColor"
                    rules="required"
                >
                  <b-form-group
                      label="Background color"
                      label-for="h-backgroundColor"
                  >
                    <template #label>{{
                        $t("labels.backgroundcolor")
                      }}
                    </template>
                    <b-form-input
                        id="h-backgroundColor"
                        type="color"
                        :value="header.background_color"
                        @input="
                        (newValue) =>
                          onColorChange({
                            key: 'background_color',
                            value: newValue,
                          })
                      "
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <span>Background image</span>
                <image-field-form
                    :single-button="true"
                    :text="$t('labels.backgroundimage')"
                    class="w-100 mt-1"
                    :value="header.background_image"
                    path="headers"
                    @uploadedImage="onUploadedImage($event, 'background_image')"
                    @error="errorInLoadImage"
                >
                </image-field-form>

                <div v-if="header.background_image" class="row">
                  <div class="col">

                    <img
                        style="width: 100%"
                        :src="header.background_image"
                        alt="Uploaded Image"
                        class="mt-1 rounded"
                    />

                    <b-button class="close-btn" @click="onClearImage('background_image')" variant="outline-primary"
                              size="sm">
                      X
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>

            <div class="accordion" role="tablist">
              <b-list-group class="list-group-flush mt-2">
                <b-list-group-item style="background: none" class="px-0">
                  <h6 v-b-toggle.accordion-1>{{ $t("title") }}</h6>
                  <b-collapse
                      id="accordion-1"
                      accordion="my-accordion"
                      role="tabpanel"
                  >
                    <div class="px-1 mt-1">
                      <validation-provider
                          #default="validationContext"
                          name="text"
                          class="w-100"
                      >
                        <b-form-group label="text" label-for="text">
                          <template #label>{{ $t("text") }}</template>
                          <b-form-input
                              id="text"
                              type="text"
                              @input="updatePreview()"
                              v-model="header.title.text"
                              :state="getValidationState(validationContext)"
                          />
                          <small class="text-danger">
                            {{ validationContext.errors[0] }}
                          </small>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <div class="px-1 mt-1">
                      <validation-provider
                          #default="validationContext"
                          name="textColor"
                          class="w-100"
                      >
                        <b-form-group label="textColor" label-for="textColor">
                          <template #label>{{ $t("color") }}</template>
                          <b-form-input
                              id="textColor"
                              type="color"
                              :value="header.title.color"
                              @input="
                              (newValue) =>
                                onColorChange({
                                  key: 'title.color',
                                  value: newValue,
                                })
                            "
                              :state="getValidationState(validationContext)"
                          />
                          <small class="text-danger">
                            {{ validationContext.errors[0] }}
                          </small>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <div class="px-1 mt-1">
                      <validation-provider
                          #default="validationContext"
                          name="fontSize"
                          class="w-100"
                      >
                        <b-form-group label="With" label-for="fontSize">
                          <template #label>{{ $t("font_size") }}</template>
                          <b-form-input
                              id="fontSize"
                              type="text"
                              @input="updatePreview()"
                              v-model="header.title.font_size"
                              :state="getValidationState(validationContext)"
                          />
                          <small class="text-danger">
                            {{ validationContext.errors[0] }}
                          </small>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </b-collapse>
                </b-list-group-item>

                <!--------------------------------------------------------->

                <b-list-group-item style="background: none" class="px-0">
                  <h6 v-b-toggle.accordion-2>Logo</h6>
                  <b-collapse
                      id="accordion-2"
                      accordion="my-accordion"
                      role="tabpanel"
                  >
                    <div class="px-1 mt-1">
                      <image-field-form
                          :single-button="true"
                          class="w-100"
                          text="Logo"
                          :value="header.logo.href"
                          path="headers"
                          @uploadedImage="onUploadedImage($event, 'logo.href')"
                          @error="errorInLoadImage"
                          preview="true"
                      >
                      </image-field-form>


                      <div v-if="header.logo.href" class="row">
                        <div class="col">

                          <img
                              style="width: 100%; max-width: 210px"
                              :src="header.logo.href"
                              alt="Uploaded Image"
                              class="mt-2 rounded"
                          />

                          <b-button class="close-btn" @click="onClearImage('logo.href')" variant="outline-primary"
                                    size="sm">
                            X
                          </b-button>
                        </div>
                      </div>
                    </div>

                    <div class="px-1 mt-1">
                      <validation-provider
                          #default="validationContext"
                          name="LogoWith"
                          class="w-100"
                      >
                        <b-form-group label="With" label-for="LogoWith">
                          <template #label>{{ $t("width") }}</template>
                          <b-form-input
                              id="LogoWith"
                              type="text"
                              @input="updatePreview()"
                              v-model="header.logo.with"
                              :state="getValidationState(validationContext)"
                          />
                          <small class="text-danger">
                            {{ validationContext.errors[0] }}
                          </small>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <div class="px-1 mt-1">
                      <validation-provider
                          #default="validationContext"
                          name="LogoHeight"
                          class="w-100"
                      >
                        <b-form-group label="With" label-for="LogoHeight">
                          <template #label>{{ $t("height") }}</template>
                          <b-form-input
                              id="LogoHeight"
                              type="text"
                              @input="updatePreview()"
                              v-model="header.logo.height"
                              :state="getValidationState(validationContext)"
                          />
                          <small class="text-danger">
                            {{ validationContext.errors[0] }}
                          </small>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </b-collapse>
                </b-list-group-item>

                <!--------------------------------------------------------->
                <b-list-group-item style="background: none" class="px-0">
                  <h6 v-b-toggle.accordion-3>{{ $t("usermenu") }}</h6>
                  <b-collapse
                      id="accordion-3"
                      accordion="my-accordion"
                      role="tabpanel"
                  >
                    <div class="px-1 mt-1">
                      <validation-provider
                          #default="validationContext"
                          name="ColorMenu"
                          class="w-100"
                      >
                        <b-form-group label="ColorMenu" label-for="ColorMenu">
                          <template #label>{{ $t("color") }}</template>
                          <b-form-input
                              id="ColorMenu"
                              type="color"
                              :value="header.auth.color"
                              @input="
                              (newValue) =>
                                onColorChange({
                                  key: 'auth.color',
                                  value: newValue,
                                })
                            "
                              :state="getValidationState(validationContext)"
                          />
                          <small class="text-danger">
                            {{ validationContext.errors[0] }}
                          </small>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <div class="px-1 mt-1">
                      <validation-provider
                          #default="validationContext"
                          name="BackgroundMenu"
                          class="w-100"
                      >
                        <b-form-group
                            label="BackgroundMenu"
                            label-for="BackgroundMenu"
                        >
                          <template #label
                          >{{ $t("labels.backgroundcolor") }}
                          </template>
                          <b-form-input
                              id="BackgroundMenu"
                              type="color"
                              :value="header.auth.background_color"
                              @input="
                              (newValue) =>
                                onColorChange({
                                  key: 'auth.background_color',
                                  value: newValue,
                                })
                            "
                              :state="getValidationState(validationContext)"
                          />
                          <small class="text-danger">
                            {{ validationContext.errors[0] }}
                          </small>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <div class="px-1 mt-1">
                      <validation-provider
                          #default="validationContext"
                          name="BackgroundMenu"
                          class="w-100"
                      >
                        <b-form-group
                            label="BackgroundMenu"
                            label-for="BackgroundMenu"
                        >
                          <template #label>{{ $t("text_color") }}</template>
                          <b-form-input
                              id="BackgroundMenu"
                              type="color"
                              :value="header.auth.text_color"
                              @input="
                              (newValue) =>
                                onColorChange({
                                  key: 'auth.text_color',
                                  value: newValue,
                                })
                            "
                              :state="getValidationState(validationContext)"
                          />
                          <small class="text-danger">
                            {{ validationContext.errors[0] }}
                          </small>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </b-collapse>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-list-group-item>
        </b-tab>

        <b-tab title="Settings">
          <b-list-group class="list-group-flush mt-2">
            <b-list-group-item style="background: none" class="px-0">
              <h6 v-b-toggle.accordion-1>{{ $t("position") }}</h6>

              <b-collapse
                  id="accordion-1"
                  accordion="my-accordion"
                  role="tabpanel"
              >
                <b-row>
                  <div class="col-12 col-md-9">
                    <b-form-group
                        class="w-100"
                        :label="$t('title')"
                        label-for="h-title"
                    >
                      <b-form-select
                          id="h-title"
                          v-model="header.title.position"
                          @change="updatePreview()"
                          :options="selectOptions"
                          name="options"
                      >
                      </b-form-select>
                    </b-form-group>
                  </div>

                  <div class="col-12 col-md-3">
                    <b-form-checkbox
                        class="px-0 pt-2"
                        v-model="header.title.hide"
                        switch
                        @change="updatePreview()"
                        size="md"
                    ></b-form-checkbox>
                  </div>
                </b-row>

                <b-row>
                  <div class="col-12 col-md-9">
                    <b-form-group class="w-100" label="Logo" label-for="h-logo">
                      <b-form-select
                          id="h-logo"
                          v-model="header.logo.position"
                          name="logo"
                          @change="updatePreview()"
                          :options="selectOptions"
                      ></b-form-select>
                    </b-form-group>
                  </div>

                  <div class="col-12 col-md-3">
                    <b-form-checkbox
                        class="px-0 pt-2"
                        v-model="header.logo.hide"
                        @change="updatePreview()"
                        switch
                        size="md"
                    ></b-form-checkbox>
                  </div>
                </b-row>

                <b-row>
                  <div class="col-12 col-md-9">
                    <b-form-group
                        class="w-100 mr-2"
                        :label="$t('usermenu')"
                        label-for="h-auth"
                    >
                      <b-form-select
                          id="h-auth"
                          v-model="header.auth.position"
                          @change="updatePreview()"
                          :options="selectOptions"
                          name="auth"
                      ></b-form-select>
                    </b-form-group>
                  </div>

                  <div class="col-12 col-md-3">
                    <b-form-checkbox
                        class="px-0 pt-2"
                        v-model="header.auth.hide"
                        @change="updatePreview()"
                        switch
                        size="md"
                    ></b-form-checkbox>
                  </div>
                </b-row>
              </b-collapse>
            </b-list-group-item>

            <b-list-group-item style="background: none" class="px-0">
              <h6 v-b-toggle.accordion-2>{{ $t("structure") }}</h6>

              <b-row align-v="center">
                <b-col :order="header.logo.position">
                  <b-card
                      class="text-center align-items-center"
                      v-if="!header.logo.hide"
                  >
                    <b-card-text>
                      <svg
                          class="bd-placeholder-img rounded-circle"
                          width="60"
                          height="60"
                          xmlns="http://www.w3.org/2000/svg"
                          role="img"
                          aria-label="Logo"
                          preserveAspectRatio="xMidYMid slice"
                          focusable="false"
                      >
                        <title>Logo</title>
                        <rect width="100%" height="100%" fill="#6c757d"></rect>
                        <text x="16%" y="50%" fill="#dee2e6" dy=".3em">
                          LOGO
                        </text>
                      </svg>
                    </b-card-text>
                  </b-card>
                </b-col>

                <b-col :order="header.title.position">
                  <b-card
                      class="text-center align-items-center"
                      v-if="!header.title.hide"
                  >
                    <b-card-text class="text-center align-items-center">
                      {{ $t("titulo") }}
                    </b-card-text>
                  </b-card>
                </b-col>

                <b-col :order="header.auth.position">
                  <b-card v-if="!header.auth.hide">
                    <b-card-text>
                      <b-button
                          type="button"
                          role="button"
                          pill
                          class="d-flex"
                          size="sm"
                          disabled
                      >
                        <span class="d-flex items-center align-items-center">
                          <span>{{ $t("buttons.usermenu") }}</span>
                        </span>
                      </b-button>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss">
.close-btn {
  top: 5px;
  right: 15px;
  margin-top: 5px;
  position: absolute;
}
</style>

import { render, staticRenderFns } from "./GeneralEditColor.vue?vue&type=template&id=f7992052&scoped=true"
import script from "./GeneralEditColor.vue?vue&type=script&lang=js"
export * from "./GeneralEditColor.vue?vue&type=script&lang=js"
import style0 from "./GeneralEditColor.vue?vue&type=style&index=0&id=f7992052&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7992052",
  null
  
)

export default component.exports